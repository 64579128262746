import React, { useState } from 'react'
import styled from 'styled-components'
import MenuIcon from '@mui/icons-material/Menu';
import Announcement from './Announcement'
import CloseIcon from '@mui/icons-material/Close';

function Header() {
    const [menuStatus, setmenuStatus] = useState(false);

    return (
        <Container>
            <a href="/">
                <img src="/images/tesla.svg" alt="Tesla logo" width="124px" height="18px" />
            </a>
            <Menu>
                <a href="#">Model S</a>
                <a href="#">Model 3</a>
                <a href="#">Model X</a>
                <a href="#">Model Y</a>
                <a href="#">Solar Roof</a>
                <a href="#">Solar Panels</a>
            </Menu>
            <RightMenu>
                <a href="#">Shop</a>
                <a href="#">Account</a>
                <MenuText onClick ={()=>setmenuStatus(true)}>Menu</MenuText>
            </RightMenu>
            <SideNav show={menuStatus}>
                <CloseContainer>
                    <CloseButton onClick ={()=>setmenuStatus(false)} />
                </CloseContainer>
                    <li><a href="#">Existing Inventory</a></li>
                    <li><a href="#">Used Inventory</a></li>
                    <li><a href="#">Trade-In</a></li>
                    <li><a href="#">Test Drive</a></li>
                    <li><a href="#">Cybertruck</a></li>
                    <li><a href="#">Roadster</a></li>
                    <li><a href="#">Semi</a></li>
                    <li><a href="#">Charging</a></li>
                    <li><a href="#">Powerwall</a></li>
                    <li><a href="#">Commercial Energy</a></li>
                    <li><a href="#">Utilities</a></li>
                    <li><a href="#">Find Us</a></li>
                    <li><a href="#">Support</a></li>
                    <li><a href="#">Investor Relations</a></li>
            </SideNav>
        </Container>
    )
}

export default Header

const Container = styled.div`
    min-height: 50px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    top: 0px;
    left: 0;
    right 0;
    z-index: 1;
`

const Menu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-left: 130px;

    a {
        font-weight: 600;
        padding: 0 10px;
        flex-wrap: nowrap;
        font-size: 14px;
    }
    a:hover {
        background-color: rgba(0,0,0,.05);
        border-radius: 20px;
    }

    @media(max-width: 1000px) {
        display: none;
    }
`

const RightMenu = styled.div`
    display: flex;
    align-items: center;
    a {
        font-weight: 600;
        margin-right: 10px;
        flex-wrap: nowrap;
        font-size: 14px;
        padding: 0 10px;
    }
    a:hover {
        background-color: rgba(0,0,0,.05);
        border-radius: 20px;
    }
`
const MenuText = styled.div`
    {
        font-weight: 600;
        margin-right: 10px;
        flex-wrap: nowrap;
        font-size: 14px;
        padding: 0 10px;
        cursor: pointer;
    }
    :hover {
        background-color: rgba(0,0,0,.05);
        border-radius: 20px;
    }
`

const SideNav = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    width: 300px;
    z-index: 999;
    list-style: none;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    text-align: start;
    transform: ${props => props.show ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.2s;
    overflow-y: scroll;
    li {
        padding: 5px 0;
        font-weight: 600;
        font-size: 14px;
        padding-left: 15px;
        margin: 5px 0;
    }
    li:hover {
        background-color: rgba(0,0,0,.05);
        border-radius: 20px;
    }
`

const CloseButton = styled(CloseIcon)`
    cursor: pointer;
`

const CloseContainer = styled.div`
    display: flex;
    Justify-content: flex-end;
    padding-bottom: 40px;
`