import React from 'react'
import styled from 'styled-components'

function Announcement() {
    return (
        <Container>
            <a href="#">Read Tesla's 2020 Impact Report</a>
        </Container>
    )
}

export default Announcement

const Container = styled.div`
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        text-decoration: underline;
    }
`